import { useEffect, useState } from 'react'
import Empty from '../components/Empty'
import axios from 'axios'
import {getGlobalState, useGlobalState} from '../store'
import Artworks from '../components/Artworks'
import { loadCollections } from '../services/blockchain'
import './Collections.css'


const Collections = () => {
  const [collections, setCollections] = useState([])
  const[connectedAccount] = useGlobalState('connectedAccount')
  const[currentUser] = useGlobalState('currentUser')
  const API_URL = 'https://blockchain-api-rwm8.onrender.com';
  useEffect(() => {
    const fetchNFTs = async () => {
      const response = await axios.get(`${API_URL}/nfts`)
      setCollections(response.data)
    }

    fetchNFTs()
  }, [])
  return (
    <>
   
    <div className="w-4/5 h-25 py-10 mx-auto justify-center">
    <h4 className="text-xl capitalize text-white mb-4">Our Collections</h4>

  </div>
    <div className="nft-section" style={{height: 'auto'}}>
      
    {collections.length > 0 ? (
  collections.filter(nft => nft.active === true || nft.active === undefined).map((nft) => (
    <div className="card" key={nft.name}>
      <img className="nft" src={nft.image} alt={nft.name} />
      <div className="details">
        <p>{nft.name}</p>
        <p>Price {nft.price} ETH</p>
        <p>{nft.description}</p>
      </div>
    </div>
  ))
) : (
  <Empty />
)}
  </div>
  </>
  )
}

export default Collections
// import { useEffect } from 'react'
// import Empty from '../components/Empty'
// import {getGlobalState, useGlobalState} from '../store'
// import Artworks from '../components/Artworks'
// import { loadCollections } from '../services/blockchain'

// const Collections = () => {
//   const [collections] = useGlobalState('collections')
//   const[connectedAccount] = useGlobalState('connectedAccount')
//   const[currentUser] = useGlobalState('currentUser')
//   useEffect(async () => {
//     await loadCollections()
//   })
//   return (
//     <div>
//       {collections.length > 0 ? (
//         <Artworks title="Your Collections" auctions={collections} showOffer />
//       ) : (
//         <Empty />
//       )}
//     </div>
//   )
// }

// export default Collections
