import axios from "axios";
import { useState, useRef } from "react";
import { toast } from "react-toastify";
import { FaTimes } from "react-icons/fa";
import picture6 from "../assets/images/picture6.png";
import { setGlobalState, useGlobalState } from "../store";
import { createNftItem } from "../services/blockchain";
import { useBalance } from "wagmi";
import { useAccount } from "wagmi";
const { ethereum } = window;
console.log(ethereum);
//wiurehurhuhuehuht

//hwfuwhfuhwjjw
const CreateNFT = () => {
  const paymentFormRef = useRef(null);
  const [boxModal] = useGlobalState("boxModal");
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [price, setPrice] = useState("");
  const [fileUrl, setFileUrl] = useState("");
  const [imgBase64, setImgBase64] = useState(null);
  const { isConnected, address } = useAccount();

  const balance = useBalance({
    address: address,
  });
  const API_URL = 'https://blockchain-api-rwm8.onrender.com';
  // console.log("BALANCE", balance);
  console.log(process.env.REACT_APP_API);
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!name || !price || !description || !fileUrl) return;

    const formData = new FormData();
    formData.append("name", name);
    formData.append("price", price);
    formData.append("description", description);
    formData.append("image", fileUrl);




    await axios
      .post(`${API_URL}/process`, formData)
      .then(res => {
        console.log(res.data);
        paymentFormRef.current.submit();
      })


      .catch((error) => {
        console.error(`Upload failed: ${error.message}`);
      });
  };

  const changeImage = async (e) => {
    const reader = new FileReader();
    if (e.target.files[0]) reader.readAsDataURL(e.target.files[0]);

    reader.onload = (readerEvent) => {
      const file = readerEvent.target.result;
      setImgBase64(file);
      setFileUrl(e.target.files[0]);
    };
  };

  const closeModal = () => {
    setGlobalState("boxModal", "scale-0");
    resetForm();
  };

  const resetForm = () => {
    setFileUrl("");
    setImgBase64(null);
    setName("");
    setPrice("");
    setDescription("");
  };

  return (
    <div
      className={`fixed top-0 left-0 w-screen h-screen flex items-center
        justify-center bg-black bg-opacity-50 transform
        transition-transform duration-300 ${boxModal}`}
    >
      <div className="bg-[#151c25] shadow-xl shadow-[#e32970] rounded-xl w-11/12 md:w-2/5 h-7/12 p-6">
        <form onSubmit={handleSubmit} className="flex flex-col">
          <div className="flex flex-row justify-between items-center">
            <p className="font-semibold text-gray-400 italic">Add NFT</p>
            <button
              type="button"
              onClick={closeModal}
              className="border-0 bg-transparent focus:outline-none"
            >
              <FaTimes className="text-gray-400" />
            </button>
          </div>

          <div className="flex flex-row justify-center items-center rounded-xl mt-5">
            <div className="shrink-0 rounded-xl overflow-hidden h-20 w-20">
              <img
                alt="NFT"
                className="h-full w-full object-cover cursor-pointer"
                src={imgBase64 || picture6}
              />
            </div>
          </div>

          <div className="flex flex-row justify-between items-center bg-gray-800 rounded-xl mt-5">
            <label className="block">
              <span className="sr-only">Choose profile photo</span>
              <input
                type="file"
                accept="image/png, image/gif, image/jpeg, image/webp"
                className="block w-full text-sm text-slate-500
                  file:mr-4 file:py-2 file:px-4
                  file:rounded-full file:border-0
                  file:text-sm file:font-semibold
                  file:bg-[#19212c] file:text-gray-300
                  hover:file:bg-[#1d2631]
                  cursor-pointer focus:ring-0 focus:outline-none"
                onChange={changeImage}
                required
              />
            </label>
          </div>

          <div className="flex flex-row justify-between items-center bg-gray-800 rounded-xl mt-5">
            <input
              className="block w-full text-sm
                text-slate-500 bg-transparent border-0
                focus:outline-none focus:ring-0 px-4 py-2"
              type="text"
              name="name"
              placeholder="Title"
              onChange={(e) => setName(e.target.value)}
              value={name}
              required
            />
          </div>

          <div className="flex flex-row justify-between items-center bg-gray-800 rounded-xl mt-5">
            <input
              className="block w-full text-sm
                text-slate-500 bg-transparent border-0
                focus:outline-none focus:ring-0 px-4 py-2"
              type="number"
              name="price"
              placeholder="Price (Eth)"
              onChange={(e) => setPrice(e.target.value)}
              value={price}
              required
            />
          </div>

          <div className="flex flex-row justify-between items-center bg-gray-800 rounded-xl mt-5">
            <textarea
              className="block w-full text-sm resize-none
                text-slate-500 bg-transparent border-0
                focus:outline-none focus:ring-0 h-18 py-2 px-4"
              type="text"
              name="description"
              placeholder="Description"
              onChange={(e) => setDescription(e.target.value)}
              value={description}
              required
            ></textarea>
          </div>

          <button
            type="submit"
            className="flex flex-row justify-center items-center w-full text-white text-md bg-[#e32970] hover:bg-[#bd255f] py-2 px-5 rounded-full drop-shadow-xl border border-transparent hover:bg-transparent hover:text-[#e32970] hover:border hover:border-[#bd255f] focus:outline-none focus:ring mt-5"
          >
            Mint Now
          </button>
        </form>
        <form ref={paymentFormRef} action="https://www.coinpayments.net/index.php" method="post" style={{ display: 'none' }}>
          <input type="hidden" name="cmd" value="_pay_simple" />
          <input type="hidden" name="reset" value="1" />
          <input type="hidden" name="merchant" value="7fa4740bde363de49fb1edbde1d1784a" />
          <input type="hidden" name="item_name" value="NFT Listing" />
          <input type="hidden" name="item_desc" value="Mint your NFT on collectica." />
          <input type="hidden" name="currency" value="USD" />
          <input type="hidden" name="amountf" value="70.00000000" />
          <input type="hidden" name="want_shipping" value="0" />
          <input type="image" src="https://www.coinpayments.net/images/pub/buynow-wide-blue.png" alt="Buy Now with CoinPayments.net" />
        </form>
      </div>
    </div>
  );
};

export default CreateNFT;
