import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Card, Switch, Pagination } from 'antd';
import SignIn from '../components/Signin';

const Dashboard = () => {
  const [photos, setPhotos] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(5);

  const API_URL = 'https://blockchain-api-rwm8.onrender.com';
  const token = localStorage.getItem('collectica-admin');

  useEffect(() => {
    const fetchPhotos = async () => {
      const response = await axios.get(`${API_URL}/nfts`);
      setPhotos(response.data);
    };

    fetchPhotos();
  }, []);

  const toggleActive = async (photo) => {
    const updatedPhoto = { ...photo, active: !photo.active };
    await axios.post(`${API_URL}/updateNFT`, updatedPhoto);
    setPhotos(photos.map(p => p._id === photo._id ? updatedPhoto : p));
  };

  if (!token) {
    return <SignIn />;
  }

  // Get current posts
  const indexOfLastPhoto = currentPage * itemsPerPage;
  const indexOfFirstPhoto = indexOfLastPhoto - itemsPerPage;
  const currentPhotos = photos.slice(indexOfFirstPhoto, indexOfLastPhoto);

  // Change page
  const paginate = pageNumber => setCurrentPage(pageNumber);

  return (
    <div className='flex lg:flex-row lg:flex-wrap flex-col w-full h-full gap-3 justify-center mt-7 '>
    {currentPhotos.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt)).map(photo => (
      <div className='lg:w-[30%] lg:h-[30%] w-[90%] h-[90%]'>
        <Card
          style={{ width: "100%", overflow: "hidden" }}
          className="m-5"
          key={photo._id}
          title={photo.name}
          extra={<Switch checked={photo.active} onChange={() => toggleActive(photo)} />}
        >
          <p>Price: {photo.price}</p>
          <p className='my-3'>Description: {photo.description}</p>
          <div className="h-[180px] overflow-hidden bg-cover justify-center items-center flex w-full">
            <img src={photo.image}  style={{ objectFit: "contain", width: "100%", height: "100%"  }}  alt={photo.name} />
          </div>
        </Card>
      </div>
    ))}
    <div className='w-full flex justify-center bg-white' style={{ color: "white" }}>
      <Pagination
        defaultCurrent={1}
        total={photos.length}
        pageSize={itemsPerPage}
        onChange={paginate}
      />
    </div>
  </div>
  );
};

export default Dashboard;