
const Empty = () => {
  return (
    <div className="w-4/5 h-48 py-10 mx-auto justify-center">
      <h4 className="text-xl capitalize text-white mb-4">Nothing here bring some artworks</h4>

    </div>
  )
}

export default Empty
