
import { toast } from 'react-toastify'
import { BsArrowRightShort } from 'react-icons/bs'
import picture0 from '../assets/images/picture2.png'
import picture1 from '../assets/images/picture1.png'
import picture2 from '../assets/images/picture3.png'
import picture3 from '../assets/images/picture4.png'
import picture4 from '../assets/images/picture5.png'
import picture5 from '../assets/images/picture6.png'
import picture6 from '../assets/images/picture7.png'
import picture7 from '../assets/images/picture8.png'
import picture8 from '../assets/images/picture9.png'
import { setGlobalState, useGlobalState } from '../store'

import {ConnectButton} from "@rainbow-me/rainbowkit";
import Artworks from "./dummyArtworks";

const Bidder = () => (
    <div
        className="w-full text-white overflow-hidden bg-gray-800 rounded-md shadow-xl
    shadow-black md:w-3/5 lg:w-2/5 md:mt-0 font-sans"
    >
        <img src={picture0} alt="nft" className="object-cover w-full h-60" />
        <div
            className="shadow-lg shadow-gray-400 border-4 border-[#ffffff36]
      flex flex-row justify-between items-center px-3"
        >
            <div className="p-2">
                Current Bid
                <div className="font-bold text-center">2.231 </div>
            </div>
            <div className="p-2">
                Auction End
                <div className="font-bold text-center">20:10</div>
            </div>
        </div>
        <div
            className="bg-[#e32970] hover:bg-[#bd255f] w-full h-[40px] p-2 text-center
    font-bold font-mono "
        >
            Place a Bid
        </div>
    </div>
)

const auctions =[
    {
        id: 1,
        title: 'NFT Artwork 1',
        artist: 'Artist Name 1',
        currentBid: '1.5 ',
        imageURL: picture1,
        tokenId: 'token1', // Add the tokenId property
        image: picture1, // Add the image property
        price: '999 ', // Add the price property
        live: true, // Add the live property
        duration: Date.now() + 7200000, // Add the duration property (1 hour from now)
        biddable: true, // Add the biddable property
        disabled: true, // Add the disabled property

    },
    {
        id: 2,
        title: 'NFT Artwork 2',
        artist: 'Artist Name 2',
        currentBid: '2 ',
        imageURL: 'https://example.com/image2.jpg',
        tokenId: 'token2', // Add the tokenId property
        image: picture2, // Add the image property
        price: '1900 ', // Add the price property
        live: true, // Add the live property
        duration: Date.now() + 8200000, // Add the duration property (1 hour ago)
        biddable: true, // Add the biddable property
        disabled: true, // Add the disabled property
    },  {
        id: 2,
        title: 'NFT Artwork 2',
        artist: 'Artist Name 2',
        currentBid: '2 ',
        imageURL: 'https://example.com/image2.jpg',
        tokenId: 'token2', // Add the tokenId property
        image: picture3, // Add the image property
        price: '1900 ', // Add the price property
        live: true, // Add the live property
        duration: Date.now() + 9200000 , // Add the duration property (1 hour ago)
        biddable: true, // Add the biddable property
        disabled: true, // Add the disabled property
    },  {
        id: 2,
        title: 'NFT Artwork 2',
        artist: 'Artist Name 2',
        currentBid: '2 ',
        imageURL: 'https://example.com/image2.jpg',
        tokenId: 'token2', // Add the tokenId property
        image: picture4, // Add the image property
        price: '1900 ', // Add the price property
        live: true, // Add the live property
        duration: Date.now() +  6200000, // Add the duration property (1 hour ago)
        biddable: true, // Add the biddable property
        disabled: true, // Add the disabled property
    },  {
        id: 2,
        title: 'NFT Artwork 3',
        artist: 'Artist Name 3',
        currentBid: '29 ',
        imageURL: 'https://example.com/image2.jpg',
        tokenId: 'token2', // Add the tokenId property
        image: picture5, // Add the image property
        price: '900 ', // Add the price property
        live: true, // Add the live property
        duration: Date.now() +  5200000, // Add the duration property (1 hour ago)
        biddable: true, // Add the biddable property
        disabled: true, // Add the disabled property
    }, {
        id: 4,
        title: 'NFT Artwork 4',
        artist: 'Artist Name 4',
        currentBid: '29 ',
        imageURL: 'https://example.com/image2.jpg',
        tokenId: 'token2', // Add the tokenId property
        image: picture6, // Add the image property
        price: '700 ', // Add the price property
        live: true, // Add the live property
        duration: Date.now() +  5200000, // Add the duration property (1 hour ago)
        biddable: true, // Add the biddable property
        disabled: true, // Add the disabled property
    }, {
        id: 4,
        title: 'NFT Artwork 4',
        artist: 'Artist Name 4',
        currentBid: '29 ',
        imageURL: 'https://example.com/image2.jpg',
        tokenId: 'token2', // Add the tokenId property
        image: picture7, // Add the image property
        price: '500 ', // Add the price property
        live: true, // Add the live property
        duration: Date.now() +  9200000, // Add the duration property (1 hour ago)
        biddable: true, // Add the biddable property
        disabled: true, // Add the disabled property
    }, {
        id: 4,
        title: 'NFT Artwork 5',
        artist: 'Artist Name 5',
        currentBid: '29 ',
        imageURL: 'https://example.com/image2.jpg',
        tokenId: 'token2', // Add the tokenId property
        image: picture8, // Add the image property
        price: '500 ', // Add the price property
        live: true, // Add the live property
        duration: Date.now() +  4700000, // Add the duration property (1 hour ago)
        biddable: true, // Add the biddable property
        disabled: true, // Add the disabled property
    },
    // Add more dummy data as needed
];

const Dummy = () => {

    return (
        <div>
            <Artworks auctions={auctions} />
        </div>
    )
}

export default Dummy;