import './index.css'
import App from './App'
import React from 'react'
import ReactDOM from 'react-dom'
import 'react-toastify/dist/ReactToastify.css'
// import { initCometChat } from './services/chat'
import { BrowserRouter } from 'react-router-dom'

import '@rainbow-me/rainbowkit/styles.css';
import {darkTheme, getDefaultWallets, midnightTheme, RainbowKitProvider} from '@rainbow-me/rainbowkit';
import { configureChains, createConfig, WagmiConfig } from 'wagmi';
import {
    mainnet,
    polygon,
    optimism,
    arbitrum,
    base,
    zora,
    goerli,
    sepolia,
    localhost,
} from 'wagmi/chains';
import { publicProvider } from 'wagmi/providers/public';

const { chains, publicClient, webSocketPublicClient } = configureChains(
    [
        mainnet,
        // polygon,
        // optimism,
        // arbitrum,
        // base,
        // zora,
        // sepolia,


        // ...(process.env.REACT_APP_ENABLE_TESTNETS === 'true' ? [goerli,sepolia, localhost] : []),
    ],
    [publicProvider()]
);


const { connectors } = getDefaultWallets({
    appName: 'RainbowKit demo',
    projectId: "ae6535065899e156fa7f2fc33cd68c60", // Replace with your actual project ID
    chains,
});

const wagmiConfig = createConfig({
    autoConnect: true,
    connectors,
    publicClient,
    webSocketPublicClient,
});


  ReactDOM.render(
    <BrowserRouter>
        <WagmiConfig config={wagmiConfig}>
            <RainbowKitProvider chains={chains} theme={darkTheme()} coolMode >
      <App />

            </RainbowKitProvider>
        </WagmiConfig>
    </BrowserRouter>,
    document.getElementById('root'),
  )

