// import { Link } from 'react-router-dom'
// import { connectWallet } from '../services/blockchain'
// import {setGlobalState, truncate, useGlobalState} from '../store'
// import {toast} from "react-toastify";
// import {loginWithCometChat, signUpWithCometChat} from "../services/chat";
// import {BsArrowRightShort} from "react-icons/bs";
// import React, { useState } from 'react';
// import {ConnectButton} from "@rainbow-me/rainbowkit";
// const Header = () => {
//   const [connectedAccount] = useGlobalState('connectedAccount')
//     const [currentUser] = useGlobalState('currentUser')
//     const [isOpen, setIsOpen] = useState(false);
//
//     const toggleMenu = () => {
//         setIsOpen(!isOpen);
//     };
//     const handleLogin = async () => {
//         await toast.promise(
//             new Promise(async (resolve, reject) => {
//                 await loginWithCometChat()
//                     .then((user) => {
//                         setGlobalState('currentUser', user)
//                         console.log(user)
//                         resolve()
//                     })
//                     .catch((err) => {
//                         console.log(err)
//                         reject()
//                     })
//             }),
//             {
//                 pending: 'Signing in...',
//                 success: 'Logged in successful 👌',
//                 error: 'Error, are you signed up? 🤯',
//             },
//         )
//     }
//
//     const handleSignup = async () => {
//         await toast.promise(
//             new Promise(async (resolve, reject) => {
//                 await signUpWithCometChat()
//                     .then((user) => {
//                         console.log(user)
//                         resolve(user)
//                     })
//                     .catch((err) => {
//                         console.log(err)
//                         reject(err)
//                     })
//             }),
//             {
//                 pending: 'Signing up...',
//                 success: 'Signned up successful 👌',
//                 error: 'Error, maybe you should login instead? 🤯',
//             },
//         )
//     }
//
//     return (
//     <nav className="w-4/5 flex flex-row md:justify-center justify-between items-center py-4 mx-auto">
//       <div className="md:flex-[0.5] flex-initial justify-center items-center">
//         <Link to="/" className="text-white">
//           <span className="px-2 py-1 font-bold text-3xl italic">Dapp</span>
//           <span className="py-1 font-semibold italic">Auction-NFT</span>
//         </Link>
//       </div>
//
//       <ul
//         className="md:flex-[0.5] text-white md:flex
//       hidden list-none flex-row justify-between
//       items-center flex-initial"
//       >
//         <Link to="/" className="mx-4 cursor-pointer">Market</Link>
//         <Link to="/collections" className="mx-4 cursor-pointer">Collection</Link>
//         <Link className="mx-4 cursor-pointer">Artists</Link>
//         <Link className="mx-4 cursor-pointer">Community</Link>
//       </ul>
//         {/*<div className="flex flew-row text-5xl m-2 ">*/}
//         {/*    {!currentUser ? (*/}
//         {/*        <div className="flex justify-start items-center space-x-2">*/}
//         {/*            <button*/}
//         {/*                className="shadow-xl shadow-black text-white*/}
//         {/*  bg-[#e32970] hover:bg-[#bd255f] md:text-xs p-2*/}
//         {/*  rounded-full cursor-pointer text-xs sm:text-base"*/}
//         {/*                onClick={handleLogin}*/}
//         {/*            >*/}
//         {/*                Login Now*/}
//         {/*            </button>*/}
//         {/*            <button*/}
//         {/*                className="shadow-xl shadow-black text-white*/}
//         {/*  bg-[#e32970] hover:bg-[#bd255f] md:text-xs p-2*/}
//         {/*  rounded-full cursor-pointer text-xs sm:text-base"*/}
//         {/*                onClick={handleSignup}*/}
//         {/*            >*/}
//         {/*                Signup Now*/}
//         {/*            </button>*/}
//         {/*        </div>*/}
//         {/*    ) : (*/}
//         {/*        <button*/}
//         {/*            className="shadow-xl shadow-black text-white*/}
//         {/*  bg-[#e32970] hover:bg-[#bd255f] md:text-xs p-2*/}
//         {/*  rounded-full cursor-pointer text-xs sm:text-base"*/}
//         {/*            onClick={() => setGlobalState('boxModal', 'scale-100')}*/}
//         {/*        >*/}
//         {/*            Create NFT*/}
//         {/*            /!*<BsArrowRightShort className="font-bold animate-pulse" />*!/*/}
//         {/*        </button>*/}
//         {/*    )}*/}
//         {/*</div>*/}
//
//       {/*{(connectedAccount) ? (*/}
//       {/*  <button*/}
//       {/*    className="shadow-xl shadow-black text-white*/}
//       {/*    bg-[#e32970] hover:bg-[#bd255f] md:text-xs p-2*/}
//       {/*    rounded-full cursor-pointer text-xs sm:text-base"*/}
//       {/*  >*/}
//       {/*    {truncate(connectedAccount, 4, 4, 11)}*/}
//       {/*  </button>*/}
//       {/*) : (*/}
//       {/*  <button*/}
//       {/*    className="shadow-xl shadow-black text-white*/}
//       {/*   bg-[#e32970] hover:bg-[#bd255f] md:text-xs p-2*/}
//       {/*    rounded-full cursor-pointer text-xs sm:text-base"*/}
//       {/*    onClick={connectWallet}*/}
//       {/*  >*/}
//       {/*    Connect Wallet*/}
//       {/*  </button>*/}
//       {/*)}*/}
//
//         <div  className="shadow-xl shadow-black text-white
//           bg-[#e32970] hover:bg-[#bd255f] md:text-xs p-2
//           rounded-full cursor-pointer text-xs sm:text-base">
//
//             <ConnectButton
//                 accountStatus={{
//                     smallScreen: 'avatar',
//                     largeScreen: 'full',
//                 }}
//
//                 showBalance={{
//                     smallScreen: false,
//                     largeScreen: true,
//                 }}
//
//  />
//         </div>
//
//         {/*<ConnectButton />*/}
//     </nav>
//   )
// }
// export default Header

import { Link } from "react-router-dom";
import { connectWallet } from "../services/blockchain";
import { setGlobalState, truncate, useGlobalState } from "../store";
import { toast } from "react-toastify";
// import {loginWithCometChat, signUpWithCometChat} from "../services/chat";
import { BsArrowRightShort } from "react-icons/bs";
import React, { useState } from "react";
import { ConnectButton } from "@rainbow-me/rainbowkit";
import { useAccount } from "wagmi";

const divStyle = {
  borderRadius: "12px",
  background: "linear-gradient(90deg, #D13D70 2.82%, #D13D70 100%)",
  boxShadow: "0px 0px 13px 0px rgba(227, 79, 130, 0.50)",
};

const Header = () => {
  const [connectedAccount] = useGlobalState("connectedAccount");
  const [currentUser] = useGlobalState("currentUser");
  const [isOpen, setIsOpen] = useState(false);
  const [navbar, setNavbar] = useState(false);
  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };
  //   const handleLogin = async () => {
  //     await toast.promise(
  //       new Promise(async (resolve, reject) => {
  //         await loginWithCometChat()
  //           .then((user) => {
  //             setGlobalState("currentUser", user);
  //             console.log(user);
  //             resolve();
  //           })
  //           .catch((err) => {
  //             console.log(err);
  //             reject();
  //           });
  //       }),
  //       {
  //         pending: "Signing in...",
  //         success: "Logged in successful 👌",
  //         error: "Error, are you signed up? 🤯",
  //       }
  //     );
  //   };

  //   const handleSignup = async () => {
  //     await toast.promise(
  //       new Promise(async (resolve, reject) => {
  //         await signUpWithCometChat()
  //           .then((user) => {
  //             console.log(user);
  //             resolve(user);
  //           })
  //           .catch((err) => {
  //             console.log(err);
  //             reject(err);
  //           });
  //       }),
  //       {
  //         pending: "Signing up...",
  //         success: "Signned up successful 👌",
  //         error: "Error, maybe you should login instead? 🤯",
  //       }
  //     );
  //   };

  return (
    <nav className="w-full  shadow">
      <div className="justify-between px-4 mx-auto lg:max-w-7xl md:items-center md:flex md:px-8">
        <div>
          <div className="flex items-center justify-between py-3 md:py-5 md:block">
            <Link to="/">
              {/*<h2 className="text-2xl font-bold text-white">Collectica</h2>*/}
              <span className="px-2 py-1 font-bold text-3xl italic text-white">
                Collectica
              </span>
              {/*<span className="py-1 font-semibold italic text-white">Auction-NFT</span>*/}
            </Link>
            <div className="md:hidden">
              <button
                className="p-2 text-gray-700 rounded-md outline-none focus:border-gray-400 focus:border"
                onClick={() => setNavbar(!navbar)}
              >
                {navbar ? (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="w-6 h-6 text-white"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                  >
                    <path
                      fillRule="evenodd"
                      d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                      clipRule="evenodd"
                    />
                  </svg>
                ) : (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="w-6 h-6 text-white"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M4 6h16M4 12h16M4 18h16"
                    />
                  </svg>
                )}
              </button>
            </div>
          </div>
        </div>
        <div>
          <div
            className={`flex-1 justify-self-center pb-3 mt-8 md:block md:pb-0 md:mt-0 ${navbar ? "block" : "hidden"
              }`}
          >
            <ul className="items-center justify-center space-y-8 md:flex md:space-x-6 md:space-y-0">
              <li className="text-white hover:text-indigo-200">
                <Link to="/">Home</Link>
              </li>{" "}
              <li className="text-white hover:text-indigo-200">
                <Link to="/market">Market</Link>
              </li>
              <li className="text-white hover:text-indigo-200">
                <Link to="/collections">Collections</Link>
              </li>

              {/*<li className="text-white hover:text-indigo-200">*/}
              {/*    <Link to="/">Community</Link>*/}
              {/*</li>*/}
            </ul>


            {/* <div className="mt-3 space-y-2 lg:hidden md:hidden">
              {connectedAccount && (
                <>
                  {!currentUser ? (
                    <>
                      <button
                        // to="javascript:void(0)"
                        onClick={handleLogin}
                        className="shadow-xl shadow-black text-white bg-[#e32970] w-full hover:bg-[#bd255f] md:text-xs p-2  cursor-pointer text-xs sm:text-base"
                      >
                        Login Now
                      </button>
                      <button
                        // to="javascript:void(0)
                        onClick={handleSignup}
                        className="shadow-xl shadow-black text-white bg-[#e32970] w-full hover:bg-[#bd255f] md:text-xs p-2  cursor-pointer text-xs sm:text-base"
                      >
                        Signup Now
                      </button>
                    </>
                  ) : (
                    <button
                      // to="javascript:void(0)
                      onClick={() => setGlobalState("boxModal", "scale-100")}
                      className="shadow-xl shadow-black text-white bg-[#e32970] w-full hover:bg-[#bd255f] md:text-xs p-2  cursor-pointer text-xs sm:text-base"
                    >
                      Create NFT
                    </button>
                  )}
                </>
              )}
            </div> */}
          </div>
        </div>

        <div className="space-x-3">

            
              <button
                onClick={() => setGlobalState("boxModal", "scale-100")}
                className="text-white w-full  rounded-sm
 flex flex-row justify-center items-center shadow-md shadow-gray-700 p-3 text-base bg-blue-500 hover:bg-blue-600 transition-all"
              >
                Create Nft
              </button>

          
        </div>
        {/*<div className="hidden space-x-3 md:inline-block">*/}
        {/*    <Link*/}
        {/*        to="javascript:void(0)"*/}
        {/*        className="px-4 py-2 text-white bg-gray-600 rounded-md shadow hover:bg-gray-800"*/}
        {/*    >*/}
        {/*        Sign in*/}
        {/*    </Link>*/}
        {/*    <Link*/}
        {/*        to="javascript:void(0)"*/}
        {/*        className="px-4 py-2 text-gray-800 bg-white rounded-md shadow hover:bg-gray-100"*/}
        {/*    >*/}
        {/*        Sign up*/}
        {/*    </Link>*/}

        {/*</div>*/}

       
      </div>
    </nav>
  );
};
export default Header;
