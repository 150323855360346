import Artworks from '../components/Artworks'
import Empty from '../components/Empty'
import Hero from '../components/Hero'
import { useGlobalState } from '../store'
import {loadAuctions} from "../services/blockchain";
import {useEffect} from "react";
import Collections from './Collections';

const Home = () => {
    const [auctions] = useGlobalState('auctions')

    useEffect(async() => {
        if(!auctions.length)
            await loadAuctions()
    },);
    return (
        <div>
            {/*<Hero />*/}
            {auctions.length > 0 ? <Artworks auctions={auctions} /> : <Collections />}
        </div>
    )
}

export default Home
