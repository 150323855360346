import Nft from './views/Nft'
import Home from './views/Home'
import Header from './components/Header'
import Footer from './components/Footer'
import { useEffect, useState } from 'react'
import PlaceBid from './components/PlaceBid'
import Collections from './views/Collections'
import Market from './views/Market'
import CreateNFT from './components/CreateNFT'
import { ToastContainer } from 'react-toastify'
import { Route, Routes } from 'react-router-dom'
import { isWallectConnected, loadAuctions ,getAllAuctions } from './services/blockchain'
import {getGlobalState, setGlobalState, useGlobalState} from './store'
import OfferItem from './components/OfferItem'
import ChangePrice from './components/ChangePrice'
import { checkAuthState } from './services/chat'
import Transactions from "./components/Transactions"
import { ConnectButton } from '@rainbow-me/rainbowkit';
import {useAccount} from "wagmi";
import Dummy from "./components/Dummy";
import Dashboard from './views/DashBoard'
import { useLocation } from 'react-router-dom';
function App() {
  const location = useLocation();
  //ssg
    const { isConnected, address } = useAccount();

  const [loaded, setLoaded] = useState(false)
  const [auction] = useGlobalState('auction')
  const [allAuctions] = useGlobalState('allauctions')
    const [currentUser] = useGlobalState('currentUser')
    const [connectedAccount] = useGlobalState('connectedAccount')

  // useEffect(async () => {
  //   // await isWallectConnected();
  //     console.log("ADDRESS",address)
  //     console.log("IS CONNECTED",isConnected)
  //     if (address) {
  //         setGlobalState('connectedAccount', address.toLowerCase())
  //     }else{
  //         setGlobalState('connectedAccount', null)
  //         setGlobalState('currentUser', null)



  //     }

  //  //    setGlobalState('connectedAccount', address.toLowerCase());
  //  // await   setGlobalState('connectedAccount', address.toLowerCase());


  //     await loadAuctions().finally(() => {
  //         setLoaded(true)

  //     })

  //     if(!isConnected){
  //         setGlobalState('currentUser', null)
  //     }

  //   await checkAuthState()
  //     .then((user) => {
  //       setGlobalState('currentUser', user)
  //         console.log("Current User 1", getGlobalState('currentUser'))


  //     })
  //     .catch((error) => setGlobalState('currentUser', null))


  //   console.log('Blockchain Loaded')
  //   // console.log("ALL AUCTIONS")
  //   // console.log(allAuctions)
  // }, [])

    // useEffect(() => {
    //     if (address) {
    //         setGlobalState('connectedAccount', address.toLowerCase())
    //     }else{
    //         setGlobalState('connectedAccount', null)
    //         setGlobalState('currentUser', null)



    //     }
    // }, [address]);


    useEffect(async () => {
        await loadAuctions().finally(() => {
            setLoaded(true)

        })
    }, []);
  

    // useEffect(() => {
    //     if(!isConnected){
    //         setGlobalState('currentUser', null)
    //         console.log("Current User 2", getGlobalState('currentUser'))
    //     }
    //
    // }, [!isConnected]);
  return (
      <div className="min-h-screen">
       

          {/*<div*/}
          {/*    style={{*/}
          {/*        display: 'flex',*/}
          {/*        justifyContent: 'flex-end',*/}
          {/*        padding: 12,*/}
          {/*    }}*/}
          {/*>*/}
          {/*    <ConnectButton />*/}
          {/*</div>*/}
          {location.pathname === '/admin' ? (
      <Dashboard />
    ) : (
      <div className="gradient-bg-hero">
      <Header />

      {loaded ? (
        <Routes>
          <Route path="/" element={<Home />} />
        


            <Route path="/market" element={<Market />} />
          <Route path="/collections" element={<Collections />} />
          <Route path="/nft/:id" element={<Nft />} />
            <Route path="/transactions" element={<Transactions />} />
        </Routes>
      ) : null}

      <CreateNFT />

      {auction ? (
        <>
          <PlaceBid />
          <OfferItem />
          <ChangePrice />
        </>
      ) :null}

            <Dummy/>

      <Transactions/>

      <Footer />
      <ToastContainer
        position="bottom-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark"
      />

    </div>
    )}

       
    </div>
  )
}
export default App
